import React from "react";
import { useParams } from "react-router-dom";
import ChaptersDashboard from "./ChaptersDashboard";
import Unauthorized from "../../../Auth/src/pages/Unauthorized";
import { useAuth } from "../../../Auth/src/pages/Auth";
import Billing from "./Billing";
import SignIn from "../../../Auth/src/pages/SignIn";
import Account from "./Account";
import TestPage from "./TestPage";
import TestCreation from "./TestCreation";
import Results from "./Results";
import UserAccount from "./UserAccount";
import Payment from "./Payment";
import ReturnPayment from "./ReturnPayment";

//import { Crisp } from "crisp-sdk-web";

export default function RouteChaptersDashboard() {
  const { isLoggedIn } = useAuth();
  const {path, id} = useParams<{ path?: string; id?: string}>();
  //Crisp.configure("21d4500c-a8f2-427e-a627-1df649d6347e");
  if (!isLoggedIn) {
    return <SignIn />;
  }

  let componentToRender;

  // Ajoutez des conditions pour déterminer quel composant afficher en fonction de path
  if (path === "vostests") {
    componentToRender = <TestPage/>;
    //componentToRender = <DashboardYourTests/>;
  } else if (path && path.startsWith("creationtest/") && id) {
    // If the path starts with "creationtest/" and an id is provided
    componentToRender = <TestCreation id={id} />;
  } else if (path === "creationtest" && id) {
    componentToRender = <TestCreation id={id}/>;
  } else if (path === "resultatcandidats") {
    componentToRender = <Results />;
  }
  else if (path === "billing") {
    componentToRender = <Billing/>;
  } 
  else if (path === "account") {
    componentToRender = <Account/>;
  }
  else if (path === "return-payment") {
    componentToRender = <ReturnPayment/>;
  }
  // else if (path === "payment") {
  //   componentToRender = <Payment/>;
  // }
  // else if (path === "help") {
  //   componentToRender = <UserAccount />;
  // }

  return (
    <div >
      <ChaptersDashboard />
      {componentToRender}
    </div>
  );
}